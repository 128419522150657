import Slider from '../Slider/Slider';
import { useEffect, useRef } from 'react';
import {
	SliderWrap, RangeValueWrap, RangeWrap, StripButoonWrap, ToolHeaderMainWrap, SliderWrapper, 
	EditButtonLaptop, EditButtonMobile, EditButtonWrap, AdjustPanelWrap, AdjustPanelHeading, InfoText
} from './styles';
import OffsetModal from '../OffsetModal/';
import { useRefDispatch } from 'contexts/RefContext';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IrradianceToggle } from 'components/Irradiance/IrradianceToggle';
import DeleteModal from 'components/tool/Editor/DeleteModal';
import EditFacetModal from 'components/tool/Editor/EditFacetModal';
import ChangeOrientation from 'components/tool/Editor/ChangeOrientation';

import PanelToggle from 'components/Irradiance/PanelToggle';
import Show3DButton from 'components/ThreeDImage/Show3DButton';
import { EditButtonDiv } from 'components/tool/Editor/ButtonDiv';
import WarningModal from 'components/tool/Editor/WarningModal';
import EditorToolBar from 'components/tool/Editor';
import SwitchProvider from 'components/SwitchProvider';
import FullViewBtn from 'components/tool/Editor/ButtonDiv/FullViewBtn';
import ShadingConfirmationModal from 'components/tool/Editor/EditFacetModal/shadingConfirmationModal';
import RecalculateFacetLayoutModal from 'components/tool/Editor/EditFacetModal/RecalculateLayoutConfirmationModal';
import Settings from 'components/Settings';
import RecalculateLayoutModal from 'components/tool/Editor/RecalculateLayoutModal';
import RecommendPanelsButton from 'components/DisplayEnergy/PanelComponent/RecommendPanelsButton';
import { useOrgTheme } from 'contexts/ThemeContext';
import ResetChangesModal from 'components/tool/Editor/ResetChangesModal';
import GetDebugDataBtn from 'components/tool/Editor/ButtonDiv/GetDebugDataBtn';
import DevModeBtn from 'components/tool/Editor/ButtonDiv/DevModeBtn';

export default function ToolWithSilder() {
	const ref = useRef<HTMLInputElement | null>(null);
	const refDispatch = useRefDispatch();
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { org } = useSelector((state: RootState) => state.roofData.data);
	const { editModeEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { accessToSettings } = useSelector((state: RootState) => state.QueryParams.data);
	const showAdjustPanelSlider = !editModeEnabled && !showIrradiance;
	const { adjustPanelText, adjustSystemSizeText } = useOrgTheme();

	useEffect(() => {
		refDispatch({ type: 'ADD_SLIDER_REF', payload: { ref } });
	}, [refDispatch]);

	return (
		<>
			<OffsetModal />
			<DeleteModal />
			<ChangeOrientation />
			<EditFacetModal />
			{/* <InfoModal/> */}
			<WarningModal />
			<RecalculateLayoutModal />
			<ResetChangesModal />
			<ShadingConfirmationModal />
			<RecalculateFacetLayoutModal />
			<SliderWrapper className={editModeEnabled ? 'EnableModeViewWrapper' : 'NonViewWrapper'}>
				<SliderWrap className={`
				${editModeEnabled ? 'EnableSliderWrapper' : 'NonEnableSliderWrapper'}
					 ${adjustSystemSizeText ? 'eqadjustWrap' : ''}`}>
					{!!org.setting?.canEdit &&
						<EditButtonWrap>
							<EditButtonDiv />
						</EditButtonWrap>}
					{!org?.setting?.isB2C && !editModeEnabled && <EditButtonLaptop >
						<SwitchProvider />
					</EditButtonLaptop>}

					{editModeEnabled && <EditorToolBar />}

					{showAdjustPanelSlider &&
						<>
							<AdjustPanelWrap className='sliderWithName'>
								<AdjustPanelHeading className='adjust-panel-container'>
									<h3>{adjustPanelText}</h3>
								</AdjustPanelHeading>
								<RangeValueWrap>
									<RangeWrap>
										<Slider ref={ref} />
									</RangeWrap>
								</RangeValueWrap>
							</AdjustPanelWrap>
							{!!adjustSystemSizeText && <InfoText>{adjustSystemSizeText}</InfoText>}
						</>

					}

				</SliderWrap>

				{!org?.setting?.isB2C && <ToolHeaderMainWrap className={editModeEnabled ? 'EnableModeView' : 'NonView'}>
					{!editModeEnabled && <EditButtonMobile ><SwitchProvider /></EditButtonMobile>}

					<ToolHeaderMainWrap className='mobile-view'>
						{showIrradiance &&
							<StripButoonWrap className='stripWrapperButtons'>
								<PanelToggle />
							</StripButoonWrap>
						}
						{accessToSettings && <Settings />}
						{!org.setting?.isB2C && !editModeEnabled && <RecommendPanelsButton/>}
						<IrradianceToggle />
						<Show3DButton />
						<GetDebugDataBtn />
						<DevModeBtn />
						<FullViewBtn />
					</ToolHeaderMainWrap>
				</ToolHeaderMainWrap>

				}

			</SliderWrapper>
		</>
	);
}