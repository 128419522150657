import { useSelector } from 'react-redux';
import { useRefs } from './RefContext';
import { RootState } from 'store';
import { createContext, useContext, useMemo } from 'react';
import { STANDARD_IMAGERY_SIZE } from '../constants';

interface ToolDimensionsAndScaleContextProps {
	scale: { x: number; y: number };
	imageDimensions: Dimensions;
}

const ToolDimensionsAndScaleContext = createContext<ToolDimensionsAndScaleContextProps | null>(null);

export default function ToolDimensionsAndScaleProvider({ children }: { children: React.ReactNode }) {
	const { konvaRef } = useRefs();
	const { imgHeight, imgWidth, org } = useSelector((state: RootState) => state.roofData.data);
	const isInFullViewMode = useSelector((state:RootState)=> state.toolNewPostions.data.fullScreenEnabled);
	const maintainAspectRatio = !!org?.setting?.canEdit;

	const konvaStageWidth = konvaRef?.current?.width() || imgWidth;
	const konvaStageHeight = konvaRef?.current?.height() || imgHeight;

	const imageDimensions = useMemo(() => {
		if (konvaRef?.current && !isInFullViewMode) {
			const aspectRatio = imgWidth / imgHeight;
			const height = konvaStageHeight < imgHeight ? imgHeight : konvaStageHeight;
			const width = maintainAspectRatio ? height * aspectRatio : konvaStageWidth;
			return { width, height };
		}
		return { width: STANDARD_IMAGERY_SIZE, height: STANDARD_IMAGERY_SIZE };
	}, [konvaRef, isInFullViewMode, imgWidth, imgHeight, konvaStageHeight, maintainAspectRatio, konvaStageWidth]);

	const scale = useMemo(() => {
		let x = 1, y = 1;
		if (konvaRef?.current) {
			x = imageDimensions.width / imgWidth;
			y = imageDimensions.height / imgHeight;
		}
		return { x, y };
	}, [imgHeight, imgWidth, konvaRef, imageDimensions]);

	return (
		<ToolDimensionsAndScaleContext.Provider value={{ scale, imageDimensions }}>
			{children}
		</ToolDimensionsAndScaleContext.Provider>
	);
}

export const useKonvaImageScale = (): Vector2d => {
	const context = useContext(ToolDimensionsAndScaleContext);
	if (!context) {
		throw new Error('useKonvaImageScale must be used within a ToolDimensionsAndScaleProvider');
	}
	return context.scale;
};

export const useKonvaImageDimensions = (): Dimensions => {
	const context = useContext(ToolDimensionsAndScaleContext);
	if (!context) {
		throw new Error('useKonvaImageDimensions must be used within a ToolDimensionsAndScaleProvider');
	}
	return context.imageDimensions;
};
